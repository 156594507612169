import { useDispatch, useSelector } from 'react-redux';
import { withinExpirationDate } from '../utils/helpers';
import { retrieveLocallyStoredValue, removeLocallyStoredValue } from '../utils/localStorage';
import type { RootState } from '../store';
import type { AuthRole } from '../types';
import { applyLocalStorageAuth } from '../store/slices/actions';

type UseAuthI = {
    isAuth: boolean;
    groups: AuthRole[] | null;
};

const useAuth = (): UseAuthI => {
    const { token, groups } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    function fetchStoredAuthentication(): UseAuthI {
        const storedValues = retrieveLocallyStoredValue<{
            accessToken: string;
            refreshToken: string;
            expirationDate: number;
            groups: AuthRole[];
        }>('netvalve_tkn_ac');
        if (storedValues) {
            const { accessToken, refreshToken, expirationDate } = storedValues;
            if (withinExpirationDate(expirationDate)) {
                dispatch(applyLocalStorageAuth({ token: accessToken, refreshToken, groups: storedValues.groups }));
                return {
                    isAuth: true,
                    groups: storedValues.groups,
                };
            }
            removeLocallyStoredValue('netvalve_tkn_ac');
        }
        return {
            // IF NO VALID LOCALLY STORED TOKENS, USER IS UNAUTHENTICATED
            isAuth: false,
            groups: null,
        };
    }

    if (!token) return fetchStoredAuthentication();

    return {
        isAuth: true,
        groups,
    };
};

export default useAuth;
