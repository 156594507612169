import React, { Component, ReactNode } from 'react';
import Layout from '../components/Layout';
import ErrorMessage from '../components/UI/ErrorMessage';

interface Props {
    children: ReactNode | ReactNode[];
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): State {
        // UPDATE STATE TO SHOW FALLBACK UI
        return { hasError: true };
    }

    componentDidCatch(_1: Error, _2: any): void {
        // HERE WE CAN PASS THE CAUGHT ERROR TO A LOGGING SERVICE
        // console.log('Uncaught error');
    }

    render(): React.ReactNode {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError)
            return (
                <Layout area="merchantUser" routePermissions={{}}>
                    <ErrorMessage error={{ error: 'Unhandled error', message: '' }} />
                </Layout>
            );
        return children;
    }
}

export default ErrorBoundary;
