import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from '../features/auth/LoginForm';
import { extractErrorMessages } from '../libs/errorHandlers';
import { authApi, useSignInMutation } from '../store/services/auth';
import useHoneypot from '../hooks/useHoneypot';

type LocationState = {
    from: string;
};

const Login: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [signIn, { isLoading, isError, error }] = useSignInMutation();
    const state = location.state as LocationState;
    const [networkError, setNetworkError] = useState(false);
    const { checkForBot } = useHoneypot();

    useEffect(() => {
        dispatch(authApi.util.resetApiState());
    }, [dispatch]);

    const submitHandler = async (values: { userName: string; password: string; inputNet: string }) => {
        setNetworkError(false);
        if (checkForBot(values.inputNet)) {
            try {
                const result = await signIn({ userName: values.userName, password: values.password });
                if ('error' in result || !result?.data?.accessToken) throw new Error();
                if (!('error' in result)) navigate(state?.from || '/', { replace: true });
            } catch (e: any) {
                setNetworkError(true);
            }
        } else {
            setNetworkError(true);
        }
    };

    return (
        <LoginForm
            submit={submitHandler}
            loading={isLoading}
            error={isError || networkError}
            errorData={extractErrorMessages(error)}
        />
    );
};

export default Login;
