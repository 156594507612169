import { GrossSaleSummaryByCardBrandsResponse } from '../../../types';

export const mockGrossSaleSummaryByCardBrands: GrossSaleSummaryByCardBrandsResponse = [
    {
        cardType: 'Discover',
        totalAmount: 1.0,
        totalTransactions: 2,
        totalPercentage: 22.22,
    },
    {
        cardType: 'MasterCard',
        totalAmount: 132.9,
        totalTransactions: 1,
        totalPercentage: 11.11,
    },
    {
        cardType: 'Visa',
        totalAmount: 56.0,
        totalTransactions: 6,
        totalPercentage: 66.67,
    },
];
