import { AuthResponse } from '../../../types';

export const mockAdminCredentials: AuthResponse = {
    accessToken: 'cvsfw3rf34fewf',
    groups: ['admin'],
    refreshToken: '353343',
    expiresIn: 3600,
    tokenType: 'Bearer',
};

export const mockClientCredentials: AuthResponse = {
    accessToken: 'cvsfw3rf34fewf',
    groups: ['merchantUser'],
    refreshToken: '353343',
    expiresIn: 3600,
    tokenType: 'Bearer',
};
