import { useParams } from 'react-router-dom';
import { useGetAllClientsQuery } from '../store/services/admin';
import { ClientNode } from '../types';

type UseSelectedClientImp = {
    selectedClientId: number;
    clientData: ClientNode | undefined;
    fetchingClient: boolean;
};

const useSelectedClient = (): UseSelectedClientImp => {
    const { data, isLoading } = useGetAllClientsQuery();
    const params = useParams<{ id: string }>();

    const findClient = () => {
        if (!params.id) return;
        return data?.find((clientNode: ClientNode) => clientNode?.id === +params.id!) as ClientNode;
    };

    const client = params.id && data ? findClient() : undefined;

    return {
        selectedClientId: +params.id! || 0,
        clientData: client,
        fetchingClient: isLoading,
    };
};

export default useSelectedClient;
