import React, { useEffect, ReactNode } from 'react';
import { Alert, Space } from 'antd';
import { FormStatus } from '../../types';
import { useToastNotification } from '../../provider/NotificationProvider';

type NotificationProps = {
    status: FormStatus;
    successMessage: string;
    successLink?: ReactNode;
    errorMessage?: string;
    errorExtras?: ReactNode;
    triggerToastNotifications?: boolean;
};

const Notifications: React.FC<NotificationProps> = ({
    status,
    successMessage,
    successLink,
    errorMessage,
    errorExtras,
    triggerToastNotifications,
}) => {
    const { success, error } = useToastNotification();

    useEffect(() => {
        let isMounted = true; // Flag to track component mount status

        if (triggerToastNotifications) {
            if (status === 'success' && isMounted) success(successMessage || 'Success!');
            if (status === 'failed' && isMounted)
                error(errorMessage || 'An error has occurred. Please try again later.');
        }

        // Clean-up function to update isMounted when component unmounts
        return () => {
            isMounted = false;
        };
    }, [status, successMessage, errorMessage, success, error, triggerToastNotifications]);

    return (
        <div data-testid="alert-notification">
            {status === 'success' && (
                <Alert
                    data-testid="successNotification"
                    message={
                        <Space>
                            {successMessage}
                            {successLink}
                        </Space>
                    }
                    type="success"
                    showIcon
                />
            )}
            {status === 'failed' && (
                <Alert
                    data-testid="ErrorNotification"
                    message={
                        <Space>
                            {errorMessage || 'There seems to be an issue. Double check your submission and try again.'}
                            {errorExtras}
                        </Space>
                    }
                    type="error"
                    showIcon
                />
            )}
        </div>
    );
};

export default Notifications;
