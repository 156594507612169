import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import useAuth from '../hooks/useAuth';

const LandingStyles = styled.div`
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-family: 'Inter', sans-serif;
        font-weight: 573;
        font-size: 6.8rem;
    }
`;

const Card = styled.div`
    background-color: white;
    min-height: 3.6rem;
    padding: 2.4rem;
`;

const Landing: React.FC = () => {
    const { groups } = useAuth();

    // REDIRECTING TO SKIP HOME PAGE
    if (groups?.includes('merchantUser')) return <Navigate to="/merchant/orders" />;
    if (groups?.includes('admin')) return <Navigate to="/admin/clients" />;

    // FALLBACK
    return (
        <Card>
            <LandingStyles>
                <h1>Netvalve</h1>
                <p> Welcome to Netvalve Back Office - your dashboard for managing merchants and merchant accounts.</p>
            </LandingStyles>
        </Card>
    );
};

export default Landing;
