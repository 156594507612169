// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from 'msw';
import { mockSummaryData } from '../mockData/mockSummaryData';
import { mockMids } from '../mockData/mockMids';
import { mockApprovalRateResponse } from '../mockData/mockApprovalRate';
import { mockGrossSaleResponse } from '../mockData/mockGrossSales';
import { mockBatchData } from '../mockData/mockBatchData';
import { mockGrossSaleSummaryByCardBrands } from '../mockData/mockGrossSaleSummaryByCardBrands';

export const successfulGetAllMids = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/getAllMids`,
    (req, res, ctx) => {
        return res(ctx.json(mockMids));
    }
);

export const successfulGetSummaryData = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/report/dashboardSummaryData`,
    (req, res, ctx) => {
        return res(ctx.json(mockSummaryData));
    }
);

export const successfulGetApprovalData = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/report/approvalRateSummaryData`,
    (req, res, ctx) => {
        return res(ctx.json(mockApprovalRateResponse));
    }
);

export const successfullGetGrossSaleData = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/report/grossSaleSummaryData`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockGrossSaleResponse));
    }
);

export const failedGetGrossSaleData = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/report/grossSaleSummaryData`,
    (req, res, ctx) => {
        return res(ctx.status(500));
    }
);

export const successfulGetBatchSummary = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/report/batchSummary`,
    (req, res, ctx) => {
        return res(ctx.json(mockBatchData));
    }
);

export const failedGetGetBatchSummary = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/report/batchSummary`,
    (req, res, ctx) => {
        return res(ctx.status(500));
    }
);

export const successfulGetGrossSaleSummaryByCardBrands = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/report/grossSaleSummaryByCardBrands`,
    (req, res, ctx) => {
        return res(ctx.json(mockGrossSaleSummaryByCardBrands));
    }
);
