import { Form, Skeleton } from 'antd';

import styled from 'styled-components';

export const FormLoaderContainer = styled.div`
    .ant-skeleton-input-sm {
        margin-bottom: 1.5rem;
    }

    .ant-skeleton-block {
        .ant-skeleton-input {
            border-width: 1px;
            border-style: solid;
            border-color: #f1f1f1;
            border-radius: 60px;
            min-height: 3em;
        }
    }
`;

type NVLoaderProps = {
    size: number;
};

const NVLoader = ({ size }: NVLoaderProps) => {
    return (
        <FormLoaderContainer data-testid="loading-skeleton">
            {Array.from({ length: size || 4 }, (_, index) => (
                <Form.Item key={index}>
                    <Skeleton.Input active size="small" />
                    <Skeleton.Input active size="default" block />
                </Form.Item>
            ))}
        </FormLoaderContainer>
    );
};

export default NVLoader;
