import { useNavigate } from 'react-router-dom';
import { FormContainer } from '../components/Forms/Forms.styled';
import NVResult from '../components/Wrappers/NVResult';
import NVButton from '../components/Wrappers/NVButton';

const NotFound: React.FC = () => {
    const navigate = useNavigate();
    const clickHandler = () => {
        navigate('/');
    };

    const PageContainer = FormContainer;

    return (
        <PageContainer maxWidth="600px" style={{ marginTop: '8rem' }}>
            <NVResult
                title="The page you are looking for does not exist."
                extra={
                    <NVButton onClick={clickHandler} type="primary" key="home">
                        Go Back
                    </NVButton>
                }
            />
        </PageContainer>
    );
};

export default NotFound;
