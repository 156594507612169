import { Breadcrumb, Layout } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;

export const NVContent = styled(Content)`
    padding: 1.5rem 2rem;
    margin: 0;

    @media (min-width: 400px) {
        padding: 1.8rem 2.5rem;
    }

    @media (min-width: 780px) {
        padding: 1.8rem 5.2rem;
    }
`;

export const NVBreadcrumb = styled(Breadcrumb)`
    margin-bottom: 2.8rem;
`;
