import { BankDTO } from '../../../types';

export const mockBankCredentialInputNames = ['client_id', 'api_key', 'test_transaction'];

export const mockBanks: BankDTO[] = [
    {
        id: 1,
        name: 'NLB',
        systemImplementation: {
            id: 1,
            name: 'EMP',
            fields: mockBankCredentialInputNames.join(','),
        },
    },
    {
        id: 2,
        name: 'TSYS',
        systemImplementation: {
            id: 2,
            name: 'TSYS',
            fields: mockBankCredentialInputNames.join(','),
        },
    },
    {
        id: 3,
        name: 'EMP',
        systemImplementation: {
            id: 3,
            name: 'EMP',
            fields: mockBankCredentialInputNames.join(','),
        },
    },
];

export const mockSystemImplementation = {
    client_id: 'testID',
    api_key: 'test_api_key',
    test_transaction: 'test',
};

// eslint-disable-next-line no-useless-escape
export const mockBankCredentials = `[{\"client_id\":\"${mockSystemImplementation.client_id}\"},{\"api_key\":\"${mockSystemImplementation.api_key}\"},{\"test_transaction\":\"${mockSystemImplementation.test_transaction}\"}]`;
