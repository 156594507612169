import { Footer } from 'antd/lib/layout/layout';
import { ReactNode, Suspense, lazy, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useSignOut from '../../hooks/useSignOut';
import { AuthRole, RoutePermissionsConfig } from '../../types';
import AdminBreadcrumbs from './AdminBreadcrumbs';
import HeaderBar from './HeaderBar';
import SideMenu from './SideMenu';
import { Canvas, Dashboard } from './SiteLayouts.styled';
import { NVContent } from '../Wrappers/NVDashboard';
import useAuth from '../../hooks/useAuth';

const SessionTimeout = lazy(() => import('../../features/auth/SessionTimeout'));

type LayoutProps = {
    area?: AuthRole;
    routePermissions: RoutePermissionsConfig;
    children?: ReactNode | ReactNode[];
};

const Layout: React.FC<LayoutProps> = ({ area, children, routePermissions }) => {
    const [openMenu, setOpenMenu] = useState(false);
    const { logOut } = useSignOut();
    const { groups } = useAuth();

    const timeoutMins = process.env.NODE_ENV === 'development' ? 30 : 5;

    return (
        <Dashboard>
            {process.env.NODE_ENV !== 'test' && (
                <Suspense>
                    <SessionTimeout timeout={1000 * 60 * timeoutMins} promptBeforeIdle={10000} onIdle={logOut} />
                </Suspense>
            )}
            <SideMenu
                userRoles={groups || []}
                routePermissions={routePermissions}
                open={openMenu}
                closeMenu={() => setOpenMenu(false)}
            />
            <Canvas>
                <HeaderBar open={openMenu} setOpenMenu={setOpenMenu} logOut={logOut} />
                <NVContent>
                    {area === 'admin' && <AdminBreadcrumbs />}
                    <Outlet />
                    {children}
                </NVContent>
                <Footer style={{ textAlign: 'center', background: '#e5e5e5' }}>
                    NetValve ©{new Date().getFullYear()}
                </Footer>
            </Canvas>
        </Dashboard>
    );
};

export default Layout;
