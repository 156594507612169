import { Button } from 'antd';
import styled from 'styled-components';

const NVButton = styled(Button)`
    padding: 2rem 2.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;

    .ant-btn-default {
        background: green;
        color: red !important;
        border-color: red;
    }
`;

export default NVButton;
