import { SummaryDataResponse } from '../../../types';

export const mockSummaryData: SummaryDataResponse = {
    totalSale: 220,
    averageSale: 260,
    totalTransactions: 180,
    totalOrders: 122,
    totalRefunds: 138,
    totalRefundAmount: 150,
    incrementList: [
        {
            dateTime: '2023-07-05',
            totalSaleAmount: 432.0,
            totalRefundAmount: 10.0,
            totalRebillAmount: 30.0,
            totalGrossAmount: 442.0,
            totalTransactions: 11,
        },
        {
            dateTime: '2023-07-06',
            totalSaleAmount: 1933.0,
            totalRefundAmount: 810.0,
            totalRebillAmount: 920.0,
            totalGrossAmount: 2743.0,
            totalTransactions: 33,
        },
        {
            dateTime: '2023-07-10',
            totalSaleAmount: 1175.2,
            totalRefundAmount: 20.0,
            totalRebillAmount: 120.0,
            totalGrossAmount: 1195.2,
            totalTransactions: 33,
        },
        {
            dateTime: '2023-07-11',
            totalSaleAmount: 1740.65,
            totalRefundAmount: 164.15,
            totalRebillAmount: 196.0,
            totalGrossAmount: 1904.8,
            totalTransactions: 58,
        },
        {
            dateTime: '2023-07-12',
            totalSaleAmount: 12509.31,
            totalRefundAmount: 1519.29,
            totalRebillAmount: 825.93,
            totalGrossAmount: 14028.6,
            totalTransactions: 1043,
        },
        {
            dateTime: '2023-07-13',
            totalSaleAmount: 450.0,
            totalRefundAmount: 1100.0,
            totalRebillAmount: 0.0,
            totalGrossAmount: 1550.0,
            totalTransactions: 8,
        },
        {
            dateTime: '2023-07-14',
            totalSaleAmount: 0.0,
            totalRefundAmount: 100.0,
            totalRebillAmount: 0.0,
            totalGrossAmount: 100.0,
            totalTransactions: 1,
        },
        {
            dateTime: '2023-07-17',
            totalSaleAmount: 5467.5,
            totalRefundAmount: 2733.75,
            totalRebillAmount: 0.0,
            totalGrossAmount: 8201.25,
            totalTransactions: 61,
        },
        {
            dateTime: '2023-07-20',
            totalSaleAmount: 22.5,
            totalRefundAmount: 11.25,
            totalRebillAmount: 0.0,
            totalGrossAmount: 33.75,
            totalTransactions: 13,
        },
        {
            dateTime: '2023-07-24',
            totalSaleAmount: 900.0,
            totalRefundAmount: 0.0,
            totalRebillAmount: 0.0,
            totalGrossAmount: 900.0,
            totalTransactions: 18,
        },
        {
            dateTime: '2023-08-03',
            totalSaleAmount: 0.0,
            totalRefundAmount: 0.0,
            totalRebillAmount: 0.0,
            totalGrossAmount: 0.0,
            totalTransactions: 3,
        },
        {
            dateTime: '2023-08-15',
            totalSaleAmount: 8169.5,
            totalRefundAmount: 3633.75,
            totalRebillAmount: 0.0,
            totalGrossAmount: 11803.25,
            totalTransactions: 80,
        },
        {
            dateTime: '2023-08-16',
            totalSaleAmount: 1822.5,
            totalRefundAmount: 911.25,
            totalRebillAmount: 0.0,
            totalGrossAmount: 2733.75,
            totalTransactions: 19,
        },
        {
            dateTime: '2023-08-18',
            totalSaleAmount: 7.5,
            totalRefundAmount: 0.0,
            totalRebillAmount: 0.0,
            totalGrossAmount: 7.5,
            totalTransactions: 2,
        },
        {
            dateTime: '2023-08-21',
            totalSaleAmount: 18.5,
            totalRefundAmount: 0.0,
            totalRebillAmount: 10.0,
            totalGrossAmount: 18.5,
            totalTransactions: 7,
        },
        {
            dateTime: '2023-08-22',
            totalSaleAmount: 10935.0,
            totalRefundAmount: 5467.5,
            totalRebillAmount: 0.0,
            totalGrossAmount: 16402.5,
            totalTransactions: 126,
        },
        {
            dateTime: '2023-08-28',
            totalSaleAmount: 2745.0,
            totalRefundAmount: 922.5,
            totalRebillAmount: 0.0,
            totalGrossAmount: 3667.5,
            totalTransactions: 33,
        },
        {
            dateTime: '2023-08-29',
            totalSaleAmount: 1372.5,
            totalRefundAmount: 911.25,
            totalRebillAmount: 0.0,
            totalGrossAmount: 2283.75,
            totalTransactions: 19,
        },
        {
            dateTime: '2023-08-30',
            totalSaleAmount: 10023.75,
            totalRefundAmount: 4995.0,
            totalRebillAmount: 0.0,
            totalGrossAmount: 15018.75,
            totalTransactions: 119,
        },
        {
            dateTime: '2023-08-31',
            totalSaleAmount: 8651.25,
            totalRefundAmount: 2272.5,
            totalRebillAmount: 0.0,
            totalGrossAmount: 10923.75,
            totalTransactions: 100,
        },
        {
            dateTime: '2023-09-04',
            totalSaleAmount: 1822.5,
            totalRefundAmount: 911.25,
            totalRebillAmount: 0.0,
            totalGrossAmount: 2733.75,
            totalTransactions: 21,
        },
    ],
};

export const mockCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'];

export const data = [[3907, 7943, 7848, 9284, 9263, 9801, 3890, 8238, 9552, 6855]];
