import type { Mid, CachedMid, EditMidPayload, RandomValue } from '../types';

export function transformMidData(data: Mid[]): CachedMid[] {
    return data.map((mid: Mid) => {
        return {
            accountName: mid.accountName,
            status: mid.status,
            currencyIds: mid.currencyDTOList.map((cur) => cur?.id),
            bankId: mid.bankDTO?.id,
            descriptor: mid.descriptor,
            resultClientDTO: mid.resultClientDTO,
            id: mid.id,
            key: mid.id,
            webAddress: mid.webAddress,
            midUsageChannel: mid.midUsageChannel,
            mccCode: mid.mccCode,
            legalName: mid.legalName,
            pciDssComplaint: mid.pciDssComplaint,
            isIFrameSupported: mid.isIFrameSupported,
            merchantOriginCountry: mid.merchantOriginCountry,
            contractStartDatetime: mid.contractStartDatetime,
            contractEndDatetime: mid.contractEndDatetime,
        } as CachedMid;
    });
}

export function transformRandomValue(value: string): RandomValue {
    return { data: value } as RandomValue;
}

export function mutateExistingMid(data: CachedMid[], editedMid: EditMidPayload): void {
    // HERE, WE ARE EDITING THE EXISTING CACHE INSTEAD OF MAKING A NEW NETWORK REQUEST
    const existingMid = data.find((cachedMid) => cachedMid.id === editedMid.id) as CachedMid;
    if (existingMid) {
        existingMid.accountName = editedMid.accountName;
        existingMid.status = editedMid.status;
        existingMid.descriptor = editedMid.descriptor;
        existingMid.currencyIds = editedMid.currencyIds;
        existingMid.bankId = editedMid.bankId;
        existingMid.legalName = editedMid.legalName;
        existingMid.webAddress = editedMid.webAddress;
        existingMid.mccCode = editedMid.mccCode;
        existingMid.midUsageChannel = editedMid.midUsageChannel;
        existingMid.merchantOriginCountry = editedMid.merchantOriginCountry;
        existingMid.contractStartDatetime = editedMid.contractStartDatetime;
        existingMid.contractEndDatetime = editedMid.contractEndDatetime;
        existingMid.pciDssComplaint = editedMid.pciDssComplaint;
        existingMid.isIFrameSupported = editedMid.isIFrameSupported;
    }
}
