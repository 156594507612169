import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import App from './App';
import ErrorBoundary from './containers/ErrorBoundary';
import './index.css';
import { store } from './store/index';
import { netvalveTheme } from './theme/theme';
import { startMockedService } from './utils/__testUtils__/mockBrowser';

const root = createRoot(document.getElementById('root')!);

if (process.env.REACT_APP_ENV === 'mocked') startMockedService();

root.render(
    <Provider store={store}>
        <ErrorBoundary>
            <BrowserRouter>
                <ConfigProvider theme={netvalveTheme}>
                    <App />
                </ConfigProvider>
            </BrowserRouter>
        </ErrorBoundary>
    </Provider>
);

// Web Vitals Docs: https://bit.ly/CRA-vitals
// reportWebVitals();
