import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import type { AuthRole } from '../../types';

type AuthGateProps = {
    allowedRoles: AuthRole[];
};

const AuthGate: React.FC<AuthGateProps> = ({ allowedRoles }) => {
    const { isAuth, groups } = useAuth();

    const location = useLocation();

    function hasRequiredGroup(roles: AuthRole[], allowed: AuthRole[]): boolean {
        for (let i = 0; i < roles.length; i++) {
            if (allowed.includes(roles[i])) return true;
        }
        return false;
    }

    if (isAuth && groups && hasRequiredGroup(groups, allowedRoles)) return <Outlet />;
    if (isAuth) return <Navigate to="/unauthorised" replace />;
    return <Navigate to="/login" state={{ from: location }} replace />;
};

export default AuthGate;
