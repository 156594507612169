export const netvalveTheme = {
    token: {
        colorSuccessText: '#3DD598',
        colorSuccess: '#3dd598',
        colorErrorText: '#FC5A5A',
        colorError: '#FC5A5A',
        colorPrimary: '#4bb3e0',
        colorInfo: '#4bb3e0',
        colorText: '#44444F',
        colorTextBase: '#44444f',
        fontSizeHeading2: 22,
        fontSizeHeading3: 20,
        fontSizeHeading4: 18,
        fontSizeHeading1: 24,
        colorBgLayout: '#E5E5E5',
        colorBgContainer: '#ffffff',
        colorTextTertiary: '#A4A4A4',
        fontFamily: 'SF Pro Text Medium',
    },
    components: {
        Button: {
            borderRadius: 60,
            paddingContentHorizontal: 21,
        },
        Tag: {
            fontFamily: 'SF Pro Text Medium',
            colorSuccess: 'orange',
            colorSuccessBorder: 'red',
        },
        Headers: {
            fontFamily: 'SF Pro Text Medium',
        },
        Input: {
            colorBorder: '#F1F1F1',
            colorError: '#FC5A5A',
            borderRadius: 60,
        },
        Select: {
            colorBorder: '#F1F1F1',
            colorError: '#FC5A5A',
            borderRadius: 60,
            controlHeight: 45,
        },
        // Radio: {
        //     padding: 30,
        // },
    },
};
