import { FormProps, Form } from 'antd';

import styled from 'styled-components';
import { useEffect } from 'react';
import { NVInputsWrapper } from './NVInput';
import NVLoader from './NVLoader';
import type { FormStatus } from '../../types';

export const FormContainer = styled.div<{ maxWidth?: string; removeXPadding?: boolean }>`
    max-width: ${({ maxWidth }) => maxWidth || '800px'};
    margin: auto;
    padding: 1.8rem 1.8rem;
    padding: ${({ removeXPadding }) => (removeXPadding ? '1.2rem 0' : '1.2rem 1.8rem')};
    min-height: 3.6rem;
    background: #ffffff;
    border-radius: 20px;

    .site-page-header {
        margin-bottom: 2rem;
    }

    .ant-skeleton-input-sm {
        width: 100px;
        min-width: 100px;
    }

    @media (max-width: 400px) {
        padding: ${({ removeXPadding }) => (removeXPadding ? '1rem 0' : '1rem')};
        margin: auto;
    }
`;

type NVFormProps = {
    children: React.ReactNode[];
    notificationCmp?: React.ReactNode;
    pageTitle?: React.ReactNode;
    maxWidth?: string;
    status?: FormStatus;
    type?: string;
    loading?: boolean;
    setInitialValuesOnSuccess?: boolean;
    removeXPadding?: boolean;
};

const NVForm = (props: NVFormProps & FormProps) => {
    const {
        children,
        pageTitle,
        notificationCmp,
        maxWidth,
        form,
        initialValues,
        status,
        setInitialValuesOnSuccess,
        removeXPadding,
    } = props;
    const formprops = { ...props };
    const isLoading = formprops.loading;
    delete formprops.pageTitle;
    delete formprops.notificationCmp;
    delete formprops.maxWidth;
    delete formprops.loading;
    delete formprops.setInitialValuesOnSuccess;
    delete formprops.removeXPadding;

    useEffect(() => {
        if (status === 'success' && !initialValues) form?.resetFields();
        else if (status === 'success' && setInitialValuesOnSuccess) form?.setFieldsValue(initialValues);
    }, [status, form, initialValues, setInitialValuesOnSuccess]);

    if (isLoading) {
        return (
            <FormContainer maxWidth={maxWidth}>
                <NVInputsWrapper>
                    {pageTitle}
                    <NVLoader size={formprops.children.length} />
                </NVInputsWrapper>
            </FormContainer>
        );
    }

    return (
        <FormContainer maxWidth={maxWidth} removeXPadding={removeXPadding}>
            <NVInputsWrapper>
                {pageTitle || null}
                <Form {...formprops} layout="vertical">
                    {children}
                </Form>
                {notificationCmp || null}
            </NVInputsWrapper>
        </FormContainer>
    );
};

export default NVForm;
