// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from 'msw';
import { mockBanks } from '../mockData/mockBanks';
import { mockClients } from '../mockData/mockClients';
import { mockMids, newCreatedMid } from '../mockData/mockMids';
import { mockCurrencies } from '../mockData/mockCurrencies';
import { defaultError } from '../mockData/mockErrors';
import { mockPaymentOptions } from '../mockData/mockPaymentOptions';
import { mockClientIDWithUsers, mockUsers } from '../mockData/mockUsers';
import { mockRandomValues } from '../mockData/mockRandomValues';
import { mockBasicAuth } from '../mockData/mockBasicAuth';
import {
    mockCssUploadResponse,
    mockImageUploadResponse,
    mockHTMLUploadResponse,
    mockTheme,
    mockAllowedPaymentOptions,
    mockPaymentButtonText,
} from '../mockData/mockDynamicLayout';
import { mockMultipleSecurityOptions } from '../mockData/mockClientSecurity';
import { mockApiKeyAuth, mockApiKeyAuthAfterDeletion } from '../mockData/mockApiKeyAuth';
import mockLogo from '../mockData/mockLogo.png';

// FETCH CLIENTS
export const successfulFetchClients = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clients`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockClients));
    }
);

export const failedFetchClients = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clients`,
    (req, res, ctx) => {
        return res(ctx.status(400), ctx.json(defaultError));
    }
);

// FETCH SINGLE CLIENT
export const successfulFetchClient = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clients/${mockClientIDWithUsers}`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockClients[0]));
    }
);

// FETCH USERS
export const successfulFetchUsers = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/users/client/*`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockUsers));
    }
);

// CREATE CLIENT
export const successfulCreateClient = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clients`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockClients[0]));
    }
);

export const failedCreateClient = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clients`,
    (req, res, ctx) => {
        return res(ctx.status(500), ctx.json(defaultError));
    }
);

export const successfulGetUser = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/users/*`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockUsers[0]));
    }
);

// CREATE USER
export const successfulCreateUser = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/users`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockUsers[0]));
    }
);

export const failedCreateUser = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/users`,
    (req, res, ctx) => {
        return res(ctx.status(500), ctx.json(defaultError));
    }
);

export const successfulUpdateUser = rest.put(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/users`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockUsers[0]));
    }
);

export const successfulResetPasswordUser = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/users`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockUsers[0]));
    }
);

export const successfulUpdateClient = rest.put(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clients`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockClients[0]));
    }
);

export const failedUpdateClient = rest.put(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clients`,
    (req, res, ctx) => {
        return res(ctx.status(500), ctx.json(defaultError));
    }
);

export const successfulGetClientMids = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/midsByClientId`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockMids));
    }
);

export const successfulGetClientMidsWithNewMid = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/midsByClientId`,
    (req, res, ctx) => {
        const updatedMids = [...mockMids];
        updatedMids.push(newCreatedMid);
        return res(ctx.status(200), ctx.json(updatedMids));
    }
);

export const failedGetClientMids = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/midsByClientId`,
    (req, res, ctx) => {
        return res(ctx.status(500), ctx.json(mockMids), ctx.json(defaultError));
    }
);

export const failedGetClientMids401Error = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/midsByClientId`,
    (req, res, ctx) => {
        return res(ctx.status(401), ctx.json(defaultError));
    }
);

export const successfulCreateNewMid = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/mids`,
    (req, res, ctx) => {
        return res(ctx.json(newCreatedMid));
    }
);

export const failedCreateNewMid = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/mids`,
    (req, res, ctx) => {
        return res(ctx.status(500), ctx.json(defaultError));
    }
);

export const successfulRequestGenkey = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/requestGenkey`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({ id: 1, midId: 1, updatedDate: new Date() }));
    }
);

export const failedRequestGenkey = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/requestGenkey`,
    (req, res, ctx) => {
        return res(ctx.status(400));
    }
);

export const successfulGetMid = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backofficemids`,
    (req, res, ctx) => {
        return res(ctx.json(mockMids[0]));
    }
);

export const failedGetMid = rest.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/mids`, (req, res, ctx) => {
    return res(ctx.status(500), ctx.json(defaultError));
});

export const successfulUpdateMid = rest.put(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/mids`,
    (req, res, ctx) => {
        return res(ctx.json(mockMids[0]));
    }
);

export const failedUpdateMid = rest.put(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/mids`,
    (req, res, ctx) => {
        return res(ctx.status(500), ctx.json(defaultError));
    }
);

export const successfulGetBanks = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/banks`,
    (req, res, ctx) => {
        return res(ctx.json(mockBanks));
    }
);

export const failedGetBanks = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/banks`,
    (req, res, ctx) => {
        return res(ctx.status(400), ctx.json(defaultError));
    }
);

export const successfulGetCurrency = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/currency`,
    (req, res, ctx) => {
        return res(ctx.json(mockCurrencies));
    }
);

export const failedGetCurrency = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/currency`,
    (req, res, ctx) => {
        return res(ctx.status(400), ctx.json(defaultError));
    }
);

export const successfulGetPaymentOptions = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/midSecurity/paymentChannels`,
    (req, res, ctx) => {
        return res(ctx.json(mockPaymentOptions));
    }
);

export const successfulGetPaymentChannelsWith3DS = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/midSecurity/paymentChannels`,
    (req, res, ctx) => {
        const data = { ...mockPaymentOptions };
        data.api3DSEnabled = true;
        data.apiEnabled = true;
        data.hpp3DSEnabled = true;
        data.hppEnabled = true;
        return res(ctx.json(data));
    }
);

export const successfulGetEmptyPaymentOptions = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/midSecurity/paymentChannels`,
    (req, res, ctx) => {
        return res(ctx.status(204));
    }
);

export const successfulGetBothEnabledPaymentOptions = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/midSecurity/paymentChannels`,
    (req, res, ctx) => {
        const data = { ...mockPaymentOptions };
        data.apiEnabled = true;
        data.hppEnabled = true;
        return res(ctx.json(data));
    }
);

export const successfulUpdatePaymentOptions = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/midSecurity/paymentChannels`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockPaymentOptions));
    }
);

export const failedUpdatePaymentOptions = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/midSecurity/paymentChannels`,
    (req, res, ctx) => {
        return res(ctx.status(400), ctx.json(defaultError));
    }
);

export const successfulFetchClientSecurity = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockMultipleSecurityOptions));
    }
);

export const successfulCreateClientSecurity = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity`,
    (req, res, ctx) => {
        return res(ctx.status(201), ctx.json(mockMultipleSecurityOptions));
    }
);

export const successfulUpdateClientSecurity = rest.put(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity`,
    (req, res, ctx) => {
        return res(ctx.status(201), ctx.json(mockMultipleSecurityOptions));
    }
);

export const failedCreateClientSecurity = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity`,
    (req, res, ctx) => {
        return res(ctx.status(400), ctx.json(defaultError));
    }
);

// export const successfulFetchAddedClientSecurity = rest.get(
//     `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity`,
//     (req, res, ctx) => {
//         return res(ctx.status(200), ctx.json(mockCli));
//     }
// );

export const failedGetClientSecurity = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(defaultError));
    }
);

// BASIC AUTH
export const successfulCreateBasicAuth = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/basicAuth`,
    (req, res, ctx) => {
        return res(ctx.status(204));
    }
);

export const successfulEditBasicAuth = rest.put(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/basicAuth`,
    (req, res, ctx) => {
        return res(ctx.status(202));
    }
);

export const failedCreateBasicAuth = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/basicAuth`,
    (req, res, ctx) => {
        return res(ctx.status(500), ctx.json(defaultError));
    }
);

export const successfulFetchEmptyBasicAuth = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/basicAuth`,
    (req, res, ctx) => {
        return res(ctx.status(200));
    }
);

export const successfulFetchBasicAuth = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/basicAuth`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockBasicAuth));
    }
);

// GENERATE RANDOM VALUE
export const successfulFetchRandomValue = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/generateRandomValue`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.text(mockRandomValues[0]));
    }
);

export const successfulGenerateClientId = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/generateRandomValue`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.text(mockRandomValues[4]));
    }
);

export const successfulGenerateAPIKey = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/generateRandomValue`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.text(mockRandomValues[5]));
    }
);

export const successfulCreatePaymentPageLayout = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/pageLayoutSettings`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({ id: 1 }));
    }
);

export const failedCreatePaymentPageLayout = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/pageLayoutSettings`,
    (req, res, ctx) => {
        return res(ctx.status(500), ctx.json(defaultError));
    }
);

export const successfulFetchTheme = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/pageLayoutSettings`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockTheme));
    }
);

export const successfulFetchEmptyTheme = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/pageLayoutSettings`,
    (req, res, ctx) => {
        return res(ctx.status(200));
    }
);

// API KEY AUTHENTICATION
export const successfulFetchApiKeyList = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/apiKey`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockApiKeyAuth));
    }
);

export const successfulFetchApiKeyListAfterDeletion = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/apiKey`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockApiKeyAuthAfterDeletion));
    }
);

export const successfulCreateApiKey = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/apiKey`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json('true'));
    }
);

export const successfulEditApiKey = rest.put(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/apiKey`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json('true'));
    }
);

export const successfulFetchApiKey = rest.put(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/apiKey`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockApiKeyAuth[0]));
    }
);

export const failedCreateApiKey = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/apiKey`,
    (req, res, ctx) => {
        return res(ctx.status(500), ctx.json(defaultError));
    }
);

export const successfulDeleteApiKey = rest.delete(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/clientSecurity/apiKey/*`,
    (req, res, ctx) => {
        return res(ctx.status(200));
    }
);

export const successfulFetchLogo = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/pageLayoutSettingsFile`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockLogo));
    }
);

export const successfulImageUpload = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/pageLayoutSettingsFile`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockImageUploadResponse));
    }
);

export const successfulCssUpload = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/pageLayoutSettingsFile`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockCssUploadResponse));
    }
);

export const successfulHTMLUpload = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/pageLayoutSettingsFile`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockHTMLUploadResponse));
    }
);

export const failedImageUpload = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/pageLayoutSettingsFile`,
    (req, res, ctx) => {
        return res(ctx.status(500));
    }
);

export const failedCssUpload = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/pageLayoutSettingsFile`,
    (req, res, ctx) => {
        return res(ctx.status(500));
    }
);

export const successfullAllowedPaymentOptions = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/allowedPaymentOptions`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockAllowedPaymentOptions));
    }
);

export const successfullPaymentButtonText = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/paymentButtonText`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockPaymentButtonText));
    }
);
