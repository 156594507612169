import { BatchT } from '../../../types';

export const mockBatchData: BatchT[] = [
    {
        batchId: 5651542774,
        currency: 'AED',
        processor: 'TSYS',
        date: '2023-08-24T15:07:01',
        saleAmount: 1967,
        numberOfSales: 1,
        numberOfRefunds: 1,
        refundAmount: 22323,
        numberOfNetTransactions: 0,
        netAmount: 0.0,
    },
    {
        currency: 'AED',
        batchId: 56512454774,
        processor: 'TSYS',
        date: '2023-08-23T15:07:01',
        saleAmount: 116467,
        numberOfSales: 3,
        numberOfRefunds: 1,
        refundAmount: 22323,
        numberOfNetTransactions: 1,
        netAmount: 2345214,
    },
    {
        currency: 'AED',
        batchId: 565435412774,
        processor: 'TSYS',
        date: '2023-08-21T15:07:01',
        saleAmount: 115967,
        numberOfSales: 1,
        numberOfRefunds: 1,
        refundAmount: 22323,
        numberOfNetTransactions: 1,
        netAmount: 46541514,
    },
    {
        currency: 'AED',
        batchId: 56512545774,
        processor: 'TSYS',
        date: '2023-08-20T15:07:01',
        saleAmount: 11667,
        numberOfSales: 1,
        numberOfRefunds: 1,
        refundAmount: 22323,
        numberOfNetTransactions: 0,
        netAmount: 0.0,
    },
];
