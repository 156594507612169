import { ApiKeyAuth } from '../../../types';
import { ApiAuthFormFields } from '../../../features/admin/ApiKeyAuthForm/ApiKeyAuthForm';

export const mockApiKeyAuth: ApiKeyAuth[] = [
    {
        id: 1,
        clientId: 8,
        midId: 3,
        securityType: 'NETVALVE_API_KEY',
        apiClientId: '19d1b825-8d6e-4901-981e-1b4ceb6b5f72',
        apiKey: '0W3_3l]NbSy-K6enwnWa',
        status: 'ACTIVE',
        description: 'Api Key Auth',
        keyName: 'Key1',
        keyUsage: 'HPP',
    },
    {
        id: 2,
        clientId: 8,
        midId: 3,
        securityType: 'NETVALVE_API_KEY',
        apiClientId: '29d1b825-8d6e-4901-981e-1b4ceb6b5f72',
        apiKey: '1W3_3l]NbSy-K6enwnWa',
        status: 'ACTIVE',
        description: 'Api Key Auth',
        keyName: 'Key2',
        keyUsage: 'HPP',
    },
];

export const mockApiKeyAuthAfterDeletion: ApiKeyAuth[] = [
    {
        id: 1,
        clientId: 8,
        midId: 3,
        securityType: 'NETVALVE_API_KEY',
        apiClientId: '19d1b825-8d6e-4901-981e-1b4ceb6b5f72',
        apiKey: '0W3_3l]NbSy-K6enwnWa',
        status: 'ACTIVE',
        description: 'Api Key Auth',
        keyName: 'Key1',
        keyUsage: 'HPP',
    },
    {
        id: 2,
        clientId: 8,
        midId: 3,
        securityType: 'NETVALVE_API_KEY',
        apiClientId: '119d1b825-8d6e-4901-981e-1b4ceb6b5f72',
        apiKey: '01W3_3l]NbSy-K6enwnWa',
        status: 'ACTIVE',
        description: 'Api Key Auth',
        keyName: 'Key1',
        keyUsage: 'HPP',
    },
];

export const mockApiKeyAuthInitialVals: ApiAuthFormFields = {
    clientId: 8,
    apiClientId: '19d1b825-8d6e-4901-981e-1b4ceb6b5f72',
    apiKey: '0W3_3l]NbSy-K6enwnWa',
    status: 'ACTIVE',
    description: 'Api Key Auth',
    keyName: 'Key1',
    keyUsage: 'HPP',
};
