// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from 'msw';
import { TransactionElement } from '../../../types';
import {
    failedTransactionResponse,
    mockCancelReferenceTransaction,
    mockAuthorisedOrder,
    mockSaleOrder,
    mockOrdersPageOne,
    mockOrdersPageTwo,
    mockRefundReferenceTransaction,
    mockSettleReferenceTransaction,
    successfulTransactionResponse,
    mockAuthorisedReferenceTransaction,
    mockSaleReferenceTransaction,
} from '../mockData/mockOrders';

export const failedExport = rest.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/export`, (req, res, ctx) => {
    return res(ctx.status(400));
});

export const successfulGetOrdersPageOne = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/orders`,
    (req, res, ctx) => {
        return res(ctx.json(mockOrdersPageOne), ctx.cookie('X-CSRF-TOKEN', 'pageone_csrf_cookie'));
    }
);

export const successfulGetOrdersPageTwo = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/orders`,
    (req, res, ctx) => {
        return res(ctx.json(mockOrdersPageTwo), ctx.cookie('X-CSRF-TOKEN', 'pagetwo_csrf_cookie'));
    }
);

export const successfulGetAllOrders = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/orders`,
    (req, res, ctx) => {
        const results = { ...mockOrdersPageOne };
        results.content = [...mockOrdersPageOne.content, ...mockOrdersPageTwo.content];
        results.totalPages = 1;
        results.totalElements = mockOrdersPageOne.content.length + mockOrdersPageTwo.content.length;
        results.size = 50;
        return res(ctx.json(results));
    }
);

export const failedGetOrders = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/orders`,
    (req, res, ctx) => {
        return res(ctx.status(400));
    }
);

export const successfulTransaction = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/transaction`,
    (req, res, ctx) => {
        return res(ctx.json(mockAuthorisedOrder));
    }
);

export const failedTransaction = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/transaction`,
    (req, res, ctx) => {
        return res(ctx.status(400));
    }
);

export const succesfulGetCapture = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/capture`,
    (req, res, ctx) => {
        return res(ctx.status(200));
    }
);

export const succesfulGetOrderAuthorised = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/order`,
    (req, res, ctx) => {
        const authorisedTransaction = mockAuthorisedReferenceTransaction;
        mockAuthorisedOrder.transactions = [authorisedTransaction];
        return res(ctx.json(mockAuthorisedOrder));
    }
);

export const succesfulGetOrderSale = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/order`,
    (req, res, ctx) => {
        const order = { ...mockSaleOrder };
        order.transactions = [mockSaleReferenceTransaction];
        return res(ctx.json(order));
    }
);

export const failedGetIndividualOrder = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/order`,
    (req, res, ctx) => {
        return res(ctx.status(400));
    }
);

export const successfulCancel = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/cancel`,
    (req, res, ctx) => {
        return res(ctx.json(successfulTransactionResponse));
    }
);

export const successfulGetOrderAfterCancel = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/order`,
    (req, res, ctx) => {
        const updatedOrder = { ...mockAuthorisedOrder };
        updatedOrder.status = 'Canceled';
        const newCancelTransaction = { ...mockCancelReferenceTransaction } as unknown as TransactionElement;
        updatedOrder.transactions = [...mockAuthorisedOrder.transactions!];
        updatedOrder.transactions.push(newCancelTransaction);
        return res(ctx.json(updatedOrder));
    }
);

export const failedCancel = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/cancel`,
    (req, res, ctx) => {
        return res(ctx.status(400));
    }
);

export const successfulCapture = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/capture`,
    (req, res, ctx) => {
        return res(ctx.json(successfulTransactionResponse));
    }
);

export const failedCapture = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/capture`,
    (req, res, ctx) => {
        return res(ctx.status(400));
    }
);

export const successfulGetOrderAfterCapture = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/order`,
    (req, res, ctx) => {
        const updatedOrder = { ...mockAuthorisedOrder };
        updatedOrder.status = 'Captured';
        const newSettleTransaction = { ...mockSettleReferenceTransaction } as unknown as TransactionElement;
        updatedOrder.transactions = [...mockAuthorisedOrder.transactions!];
        updatedOrder.transactions.push(newSettleTransaction);
        return res(ctx.json(updatedOrder));
    }
);

export const successfulRefund = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/refund`,
    (req, res, ctx) => {
        return res(ctx.json(successfulTransactionResponse));
    }
);

export const failedRefund = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/refund`,
    (req, res, ctx) => {
        return res(ctx.json(failedTransactionResponse));
    }
);

export const successfulGetOrderAfterRefund = rest.get(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/order`,
    (req, res, ctx) => {
        const updatedOrder = { ...mockAuthorisedOrder };
        updatedOrder.status = 'Refunded';
        const newRefundTransaction = { ...mockRefundReferenceTransaction } as unknown as TransactionElement;
        updatedOrder.transactions = [...mockAuthorisedOrder.transactions!];
        updatedOrder.transactions.push(newRefundTransaction);
        return res(ctx.json(updatedOrder));
    }
);
