import styled from 'styled-components';
import { Layout } from 'antd';

export const Dashboard = styled(Layout)`
    min-height: 100vh;
    .logo {
        height: 32px;
        margin: 16px;
    }
`;

export const Canvas = styled(Layout)`
    .site-layout-background {
        /* background: red; */
    }

    .ant-layout-header {
        background: rgb(1 21 41);
        height: 68px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .trigger {
        padding: 15px 0;
        font-size: 25px;
        line-height: 43px;
        cursor: pointer;
        transition: color 0.3s;
        color: white;

        :hover {
            color: black;
        }

        @media (max-width: 990px) {
            padding: 15px;
        }
    }
`;
