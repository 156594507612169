import { Navigate, Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import NotFound from './containers/NotFound';
import Login from './containers/Login';
import Layout from './components/Layout';
import AuthGate from './features/auth/AuthGate';
import Landing from './containers/Landing';
import Unauthorised from './containers/Unauthorised';
import { iframeEmbedded } from './utils/helpers';
import HppPreview from './containers/admin/PaymentPage/HppPreview';
import { NotificationProvider } from './provider/NotificationProvider';
import NVSpin from './components/Wrappers/NVSpin';

import { RoutePermissionsConfig } from './types';

const Admin = lazy(() => import('./containers/admin/Admin'));
const MerchantUser = lazy(() => import('./containers/merchant/MerchantUser'));
// const Reporting = lazy(() => import('./containers/reporting/Reporting'));

export const routePermissions: RoutePermissionsConfig = {
    '/': {
        allowedRoles: ['merchantUser', 'admin'],
        rootComponent: <Landing />,
    },
    '/admin/*': {
        allowedRoles: ['admin', 'netvalveAdmin', 'netvalveSupport'],
        rootComponent: <Admin />,
        menuLinks: ['/admin/clients'],
    },
    '/merchant/*': {
        allowedRoles: ['merchantUser', 'merchantAdmin', 'merchantSupport'],
        rootComponent: <MerchantUser />,
        // menuLinks: ['/merchant/dashboard', '/merchant/orders'], // original code
        menuLinks: ['/merchant/orders'], // hot fix
    },
    // '/reports/*': {
    //     allowedRoles: [
    //         'merchantUser',
    //         'merchantAdmin',
    //         'admin',
    //         'merchantFinance',
    //         'merchantSupport',
    //         'netvalveAdmin',
    //         'netvalveFinance',
    //         'netvalveSupport',
    //     ],
    //     rootComponent: <Reporting />,
    //     menuLinks: ['/reports'],
    // },
    '/orders': {
        allowedRoles: ['merchantUser', 'merchantAdmin', 'merchantSupport'],
        rootComponent: <Navigate to="/merchant/orders" />,
    },
    '/hpp/preview': {
        allowedRoles: ['admin', 'netvalveAdmin', 'netvalveSupport'],
        rootComponent: <HppPreview />,
    },
};

const App: React.FC = () => {
    // FRAME BUSTER FOR LEGACY BROWSERS

    if (iframeEmbedded()) return null;

    return (
        <NotificationProvider>
            <Routes>
                <Route element={<Login />} path="login" />

                {Object.entries(routePermissions).map(([routePath, routeConfig]) => (
                    <Route key={routePath} element={<AuthGate allowedRoles={routeConfig.allowedRoles} />}>
                        <Route
                            path={routePath}
                            element={
                                <Layout routePermissions={routePermissions}>
                                    <Suspense fallback={<NVSpin center />}>{routeConfig.rootComponent}</Suspense>
                                </Layout>
                            }
                        />
                    </Route>
                ))}

                <Route element={<Layout routePermissions={routePermissions} />}>
                    <Route element={<Unauthorised />} path="/unauthorised" />
                    {/* <Route element={<ErrorMessage />} path="/error" /> */}
                    <Route element={<NotFound />} path="*" />
                </Route>
            </Routes>
        </NotificationProvider>
    );
};

export default App;
