import { AuthRole } from '../types';
import { createUnixEpochElapsedExpireyTime } from '../utils/helpers';
import { retrieveLocallyStoredValue, removeLocallyStoredValue, setLocallyStoredValue } from '../utils/localStorage';

// Set the number of hours ahead that the access token will expire
// receives number of seconds
function calculateHoursUntilExpired(expiresIn: number) {
    return expiresIn / 60 / 60 - 0.5;
}

export function storeAuthToken(token: string, refreshToken: string, groups: AuthRole[], expiresIn: number): void {
    if (retrieveLocallyStoredValue('netvalve_tkn_ac')) removeLocallyStoredValue('netvalve_tkn_ac');

    const expireyDate = createUnixEpochElapsedExpireyTime(calculateHoursUntilExpired(expiresIn));

    setLocallyStoredValue('netvalve_tkn_ac', {
        accessToken: token,
        refreshToken,
        expirationDate: expireyDate,
        groups,
    });
}
