import { mockClients } from './mockClients';

export const mockClientIDWithUsers = mockClients[0].id;

export const mockUsers = [
    {
        id: 1,
        userName: 'test_merchant1',
        status: 'ACTIVE',
        role: 'MERCHANT_USER',
        clientId: 2,
        lastLoginDatetime: null,
    },
    {
        id: 16,
        userName: 'test_merchant2',
        status: 'ACTIVE',
        role: 'MERCHANT_USER',
        clientId: 2,
        lastLoginDatetime: null,
    },
    {
        id: 18,
        userName: 'test_merchant3',
        status: 'ACTIVE',
        role: 'MERCHANT_USER',
        clientId: 2,
        lastLoginDatetime: null,
    },
];
