import { ClientSecurity } from '../../../types';

// IP Addresses
export const mockClientWhiteListedIps = '94.244.107.178';
export const mockExtraClientWhiteListedIps = ['94.234.157.228'];
// HPP Urls
export const mockClientWhiteListedHpp = 'www.test1.com';
export const mockExtraClientWhiteListedHpp = ['www.test2.com', 'www.test3.com'];

export const mockSingleSecurityOption: ClientSecurity = {
    id: 1,
    clientId: 1,
    securityType: 'BASIC_AUTH',
    clientWhitelistedIPs: [mockClientWhiteListedIps, ...mockExtraClientWhiteListedIps],
    clientWhitelistedURLs: [mockClientWhiteListedHpp, ...mockExtraClientWhiteListedHpp],
    hppRestrictionEnabled: true,
    ipRestrictionEnabled: true,
};

const mockSecondSecurityOption = { ...mockSingleSecurityOption };
mockSecondSecurityOption.id = 2;

export const mockMultipleSecurityOptions = [mockSingleSecurityOption, mockSecondSecurityOption];
