import { Mid, CachedMid } from '../../../types';

export const newCreatedMid: Mid = {
    accountName: 'New Mid',
    bankCredentials: 'adswd34',
    bankDTO: {
        id: 2,
        name: 'EMP',
        systemImplementation: null,
    },
    currencyDTOList: [
        {
            id: 3,
            name: 'EUR',
        },
    ],
    descriptor: 'DSCC',
    id: 5,
    resultClientDTO: {
        id: 3,
        status: 'ACTIVE',
        userName: 'TEST2',
    },
    status: 'ACTIVE',
    webAddress: 'https://www.amazon.com/',
    midUsageChannel: 'ECOMMERCE',
    mccCode: '5311',
    legalName: 'Amazon',
    pciDssComplaint: true,
    isIFrameSupported: true,
    merchantOriginCountry: 'US',
    contractStartDatetime: '2022-01-20 12:34:56',
    contractEndDatetime: '2029-01-20 12:34:56',
};

export const mockMids: Mid[] = [
    {
        accountName: 'First Account',
        bankCredentials: 'AgV4SNNGE4G5ZZ7K',
        bankDTO: {
            id: 2,
            name: 'EMP',
            systemImplementation: null,
        },
        currencyDTOList: [
            {
                id: 3,
                name: 'EUR',
            },
        ],
        descriptor: 'DSCCAA',
        id: 1,
        resultClientDTO: {
            id: 1,
            status: 'ACTIVE',
            userName: 'TEST2',
        },
        status: 'ACTIVE',
        webAddress: 'https://www.amazon.com/',
        midUsageChannel: 'ECOMMERCE',
        mccCode: '5311',
        legalName: 'Amazon',
        pciDssComplaint: true,
        isIFrameSupported: true,
        merchantOriginCountry: 'United States',
        contractStartDatetime: '2022-01-20 12:34:56',
        contractEndDatetime: '2029-01-20 12:34:56',
    },
    {
        accountName: 'Second Account',
        bankCredentials: 'fg6',
        bankDTO: {
            id: 2,
            name: 'TSA',
            systemImplementation: null,
        },
        currencyDTOList: [
            {
                id: 3,
                name: 'EUR',
            },
        ],
        descriptor: 'TSCC',
        id: 3,
        resultClientDTO: {
            id: 3,
            status: 'ACTIVE',
            userName: 'TEST2',
        },
        status: 'ACTIVE',
        webAddress: 'https://www.amazon.com/',
        midUsageChannel: 'ECOMMERCE',
        mccCode: '5311',
        legalName: 'Amazon',
        pciDssComplaint: true,
        isIFrameSupported: true,
        merchantOriginCountry: 'US',
        contractStartDatetime: '2022-01-20 12:34:56',
        contractEndDatetime: '2029-01-20 12:34:56',
    },
];

export const mockedCachedMids: CachedMid[] = [
    {
        accountName: 'First Account',
        bankId: 1,
        descriptor: 'DSCC',
        id: 3,
        resultClientDTO: {
            id: 2,
            status: 'ACTIVE',
            userName: 'TEST1',
        },
        status: 'ACTIVE',
        currencyIds: [1],
        webAddress: 'https://www.amazon.com/',
        midUsageChannel: 'ECOMMERCE',
        mccCode: '5311',
        legalName: 'Amazon',
        pciDssComplaint: true,
        isIFrameSupported: true,
        merchantOriginCountry: 'US',
        contractStartDatetime: '2022-01-20 12:34:56',
        contractEndDatetime: '2029-01-20 12:34:56',
    },
    {
        accountName: 'Second Account',
        bankId: 2,
        descriptor: 'TSAA',
        id: 4,
        resultClientDTO: {
            id: 2,
            status: 'ACTIVE',
            userName: 'TEST1',
        },
        status: 'ACTIVE',
        currencyIds: [1],
        webAddress: 'https://www.amazon.com/',
        midUsageChannel: 'ECOMMERCE',
        mccCode: '5311',
        legalName: 'Amazon',
        pciDssComplaint: true,
        isIFrameSupported: true,
        merchantOriginCountry: 'US',
        contractStartDatetime: '2022-01-20 12:34:56',
        contractEndDatetime: '2029-01-20 12:34:56',
    },
];
