import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Layout } from 'antd';
import LogoutButton from '../UI/LogoutButton';

const { Header } = Layout;

type HeaderBarProps = {
    setOpenMenu: (open: boolean) => void;
    logOut: () => void;
    open: boolean;
};

const BtnContainer = styled.div`
    margin-right: 2rem;
`;

const HeaderBar: React.FC<HeaderBarProps> = ({ setOpenMenu, open, logOut }) => {
    return (
        <Header className="site-layout-background" style={{ padding: 0 }}>
            {open ? (
                <MenuFoldOutlined className="trigger" onClick={() => setOpenMenu(false)} />
            ) : (
                <MenuUnfoldOutlined className="trigger" onClick={() => setOpenMenu(true)} />
            )}
            <BtnContainer>
                <LogoutButton click={logOut} />
            </BtnContainer>
        </Header>
    );
};

export default HeaderBar;
