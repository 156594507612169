import { useNavigate } from 'react-router-dom';

import { StopOutlined } from '@ant-design/icons';
import { FormContainer } from '../components/Forms/Forms.styled';
import NVResult from '../components/Wrappers/NVResult';
import NVButton from '../components/Wrappers/NVButton';

const Unauthorised: React.FC = () => {
    const navigate = useNavigate();
    const clickHandler = () => {
        navigate('/', { replace: true });
    };

    const PageContainer = FormContainer;

    return (
        <PageContainer maxWidth="500px" style={{ marginTop: '8rem' }}>
            <NVResult
                icon={<StopOutlined />}
                title="You do not have the required permissions to view this page."
                extra={
                    <NVButton onClick={clickHandler} type="primary" key="home">
                        Go Back
                    </NVButton>
                }
            />
        </PageContainer>
    );
};

export default Unauthorised;
