import { AllowedPaymentOption, PaymentButtonText, PaymentPageLayoutSettingResponse } from '../../../types';

export const mockTheme = {
    id: 1,
    clientId: null,
    midId: null,
    payButtonText: 'Pay',
    bgColor: 'yellow',
    collectBillingInfo: false,
    collectShippingInfo: false,
    collectCustomerInfo: false,
    allowedPaymentOptions: ['Visa', 'Master Card'],
};

export const mockImageUploadResponse = {
    id: 'fdd0946f-0916-4f48-89fc-25aebeb55d93',
};

export const mockCssUploadResponse = {
    id: 'b91a4b38-44fb-4b5e-9b8f-9ce455f515e4',
};

export const mockHTMLUploadResponse = {
    id: 'f3288cec-54bf-4b3c-93b0-ffc7857cf827',
};

export const mockInitialVals: PaymentPageLayoutSettingResponse = {
    id: 17,
    clientId: 1,
    midId: 1,
    logoId: 'fdd0946f-0916-4f48-89fc-25aebeb55d93',
    title: 'NASW Title',
    bgColor: 'R:G:B',
    cssId: 'b91a4b38-44fb-4b5e-9b8f-9ce455f515e4',
    confirmationMsg: 'Success',
    payButtonText: 'Pay',
    collectBillingInfo: true,
    collectShippingInfo: true,
    collectCustomerInfo: true,
    allowedPaymentOptions: ['VISA', 'MASTERCARD'],
    termsAndConditions: null,
};

export const mockAllowedPaymentOptions = [
    {
        id: 1,
        code: 'VISA',
        value: 'Visa',
    },
    {
        id: 2,
        code: 'MASTERCARD',
        value: 'MasterCard',
    },
    {
        id: 3,
        code: 'AMEX',
        value: 'Amex',
    },
] as unknown as AllowedPaymentOption;

export const mockPaymentButtonText: PaymentButtonText[] = [
    {
        id: 1,
        buttonText: 'Pay',
    },
    {
        id: 2,
        buttonText: 'Donate',
    },
    {
        id: 3,
        buttonText: 'Subscribe',
    },
];
