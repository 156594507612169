import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { HomeOutlined } from '@ant-design/icons';
import useSelectedClient from '../../hooks/useSelectedClient';
import { NVBreadcrumb } from '../Wrappers/NVDashboard';

type BreadCrumbMap = {
    [key: string]: string;
};

const AdminBreadcrumbs: React.FC = () => {
    const location = useLocation();
    const { selectedClientId, clientData } = useSelectedClient();
    useParams<{ id: string }>();

    const breadcrumbNameMap: BreadCrumbMap = {
        '/admin/clients': 'Clients',
        '/admin/clients/add': 'Add',
        [`/admin/clients/${selectedClientId}`]: `${!clientData ? '' : clientData.name}`,
        [`/admin/clients/${selectedClientId}/mids`]: 'Mids',
    };

    const pathSnippets = location.pathname.split('/').filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return {
            key: url,
            title: <Link to={url}>{breadcrumbNameMap[url]}</Link>,
        };
    });
    const breadcrumbItems = [
        {
            title: (
                <Link to="/">
                    <HomeOutlined />
                </Link>
            ),
            key: 'home',
        },
    ].concat(extraBreadcrumbItems);

    return <NVBreadcrumb separator=">" items={breadcrumbItems} />;
};

export default AdminBreadcrumbs;
