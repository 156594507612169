import { InputProps, Input } from 'antd';
import styled from 'styled-components';

// THIS MUST BE USED AROUND THE ANTD FORMITEM
export const NVInputsWrapper = styled.div`
    // LABELS
    .ant-form-item-label {
        font-weight: bold;
    }

    // CHECKBOXES

    .ant-checkbox-wrapper {
        margin: 1rem 0;
        @media (min-width: 480px) {
            margin: 0.5rem;
        }
    }
    .ant-checkbox-inner {
        /* border-radius: 5rem; */
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;

        ::after {
            transform: rotate(45deg) scale(1) translate(-40%, -60%);
        }
    }
`;

const Container = styled.div`
    .ant-input,
    .ant-input-affix-wrapper {
        padding: 1rem 2.3rem;
    }

    //
    .ant-checkbox-inner {
        border-radius: 10rem;
    }
`;

const NVInput = (props: InputProps) => (
    <Container>
        <Input {...props} />
    </Container>
);

export default NVInput;
