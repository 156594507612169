// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from 'msw';
import { mockAdminCredentials, mockClientCredentials } from '../mockData/mockCredentials';

export const failedSignIn = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/users/sign-in`,
    (req, res, ctx) => {
        return res(ctx.status(401));
    }
);

export const successfulSignOut = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/users/logout`,
    (req, res, ctx) => {
        return res(ctx.status(200));
    }
);

export const successfulAdminSignIn = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/users/sign-in`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockAdminCredentials));
    }
);

export const successfulClientSignIn = rest.post(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/backoffice/users/sign-in`,
    (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(mockClientCredentials));
    }
);
