import { fetchBaseQuery, BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query/react';
import type { RootState } from '../index';
import { removeAppTokens } from '../slices/actions';
import type { ErrorResponse, TransactionResponse } from '../../types';

// SERVICES USED TO MANAGE CACHING OF NETWORK REQUESTS
// RTK QUERY PROVIDES HOOKS FOR GETTING THE CURRENT STATUS OF A REQUEST

type AppError = ErrorResponse | TransactionResponse;

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
        const { token } = (getState() as RootState).auth;
        // THIS ATTACHES THE TOKEN TO EVERY REQUEST
        if (token) headers.set('authorization', `Bearer ${token}`);

        return headers;
    },
}) as BaseQueryFn<string | FetchArgs, unknown, { status: number; data?: AppError }>;

export const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, { status: number; data?: AppError }> = async (
    args,
    api,
    extraOptions
) => {
    // BEFORE THIS, CHECK FOR TOKENS
    const result = await baseQuery(args, api, extraOptions);

    const error = result?.error;

    if (error) {
        const is401 =
            error.status === 401 || (error.data && 'responseCode' in error.data && error.data.responseCode === '401');
        if (is401) api.dispatch(removeAppTokens());
    }

    return result;
};
