import { Currency } from '../../../types';

export const mockCurrencies: Currency[] = [
    {
        id: 1,
        name: 'MKD',
    },
    {
        id: 2,
        name: 'USD',
    },
    {
        id: 3,
        name: 'EUR',
    },
];
