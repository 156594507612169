import styled from 'styled-components';

export const FormContainer = styled.div<{ maxWidth?: string }>`
    max-width: ${({ maxWidth }) => maxWidth || '900px'};
    margin: auto;
    padding: 2.4rem 5.7rem;
    min-height: 3.6rem;
    background: #ffffff;
    border-radius: 20px;

    @media (max-width: 767px) {
        padding: 1rem 1rem;
    }

    .site-page-header {
        margin-bottom: 2rem;
    }
`;

export const ButtonsFlex = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 1000px) {
        button,
        a,
        a button {
            margin-bottom: 1.5rem;
            width: 100%;
        }
    }
`;

// THIS USES ANT D COMPONET LIBRARY GRID SYSTEM AND BREAK POINTS
export const formColumns = {
    labelCol: {
        sm: {
            span: 6, // 576px
        },
        md: {
            span: 4,
            offset: 1, // 768px
        },
    },
    wrapperCol: { sm: { span: 16 } },
};

export const stackedLabels = {
    wrapperCol: { sm: { span: 24 } },
};

export const stackedButtons = {
    wrapperCol: {
        sm: { span: 24 }, // 576px
    },
};

export const formButtonColumns = {
    wrapperCol: {
        sm: { span: 12, offset: 10 }, // 576px
    },
};
