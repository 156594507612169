import React, { useEffect } from 'react';
import { Form, Checkbox, Space, Image } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import LogoPng from '../../assets/NetValve_Logo_Full_OnLight.png';
import Notifications from '../../components/Forms/Notifications';
import { createErrorString, extractErrorMessages } from '../../libs/errorHandlers';
import NVInput from '../../components/Wrappers/NVInput';
import { stackedLabels } from '../../components/Forms/Forms.styled';
import NVForm from '../../components/Wrappers/NVForm';
import NVButton from '../../components/Wrappers/NVButton';
import topPattern from '../../assets/topPattern.svg';
import bottomPattern from '../../assets/bottomPattern.svg';

export type LoginFormSubmission = {
    userName: string;
    password: string;
    inputNet: string;
};

type LoginFormProps = {
    submit: (vals: LoginFormSubmission) => void;
    error: boolean;
    loading: boolean;
    errorData?: ReturnType<typeof extractErrorMessages>;
};

const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: #06182b;
    /* background: linear-gradient(45deg, rgb(1 21 41) 20%, var(--lightblue) 20%); */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: url(${topPattern});
    background-position: 0% 0%;
    background-size: contain;
    background-repeat: no-repeat;

    @media (min-width: 900px) {
        background-size: 50%;
    }
`;

const BottomPattern = styled.div`
    position: absolute;
    z-index: 50;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(${bottomPattern});
    background-position: 100% 100%;
    background-size: contain;
    background-repeat: no-repeat;

    @media (min-width: 900px) {
        background-size: 40%;
    }
`;

const Logo = styled.div`
    max-width: 250px;
    overflow: hidden;
    margin: 2rem auto;
    margin-bottom: 3.5rem;
`;

const StyledLogin = styled.div`
    max-width: 400px;
    z-index: 100;
    width: 100%;
    padding: 0;
    background: white;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 2rem;
    margin: 2rem;
    position: relative;

    ::before {
        height: 1rem;
        content: '';
        background: #4bb3e0;
        position: absolute;
        top: -1rem;
        width: 100%;
    }

    .login-form-forgot {
        float: right;
    }
    .ant-col-rtl .login-form-forgot {
        float: left;
    }
    .login-form-button {
        width: 100%;
    }

    @media (min-width: 700px) {
        min-width: 500px;
    }
`;

const LoginForm: React.FC<LoginFormProps> = ({ submit, loading, error, errorData }) => {
    const [form] = Form.useForm();
    const inputNames = ['userName', 'password'];

    useEffect(() => {
        form.setFieldsValue({ inputNet: 'net' });
    }, [form]);

    return (
        <Container>
            <BottomPattern />
            <StyledLogin>
                <NVForm
                    {...stackedLabels}
                    form={form}
                    name="login_form"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={(vals: LoginFormSubmission) => submit(vals)}
                    notificationCmp={
                        error && (
                            <Notifications
                                status="failed"
                                successMessage="Please wait to be redirected"
                                errorMessage={createErrorString(errorData)}
                            />
                        )
                    }
                >
                    <Logo>
                        <Image id="LogoIcon" alt="Netvalve Logo" preview={false} src={LogoPng} />
                    </Logo>
                    <Form.Item
                        name={inputNames[0]}
                        // label="Username"
                        rules={[{ required: true, message: 'Please input your Username!' }]}
                    >
                        <NVInput
                            name={inputNames[0]}
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Username"
                        />
                    </Form.Item>
                    <Form.Item
                        // label="Password"
                        name={inputNames[1]}
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <NVInput
                            name={inputNames[1]}
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    {/* Honeypot field */}
                    <Form.Item name="inputNet" style={{ display: 'none' }}>
                        <NVInput />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox style={{ marginLeft: '0.5rem' }}>Remember me</Checkbox>
                        </Form.Item>
                        {/* 
                        <a className="login-form-forgot" href="/">
                            Forgot password
                        </a> */}
                    </Form.Item>

                    <Form.Item>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <NVButton
                                loading={loading}
                                shape="round"
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                            >
                                Sign In
                            </NVButton>
                        </Space>
                    </Form.Item>
                </NVForm>
            </StyledLogin>
        </Container>
    );
};

export default LoginForm;
