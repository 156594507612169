/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useRef } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Button, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    useLazyGetDynamicLayoutSettingQuery,
    useLazyGetLogoQuery,
    useLazyGetFileQuery,
} from '../../../store/services/admin';
import {
    BlobResponse,
    GetDynamicLayoutParams,
    GetLogoParams,
    PaymentPageLayoutResponse,
    GetFileUploadParams,
} from '../../../types';

const HppPreview: React.FC = () => {
    const iframe = useRef<HTMLIFrameElement>(null);
    const [searchParams] = useSearchParams();
    const themeId = searchParams.get('themeId');
    const backUrl = searchParams.get('returnUrl');
    const [getLayout] = useLazyGetDynamicLayoutSettingQuery() as unknown as [
        (args: GetDynamicLayoutParams) => Promise<{ data: PaymentPageLayoutResponse }>
    ];
    const [getLogo] = useLazyGetLogoQuery() as unknown as [(args: GetLogoParams) => Promise<{ data: string }>];
    const [getFile] = useLazyGetFileQuery() as unknown as [
        (args: GetFileUploadParams) => Promise<{ data: BlobResponse }>
    ];

    const hasFetched = useRef(false);

    useEffect(() => {
        notification.info({
            message: 'Preview Page',
            description: 'This page is for preview purposes only.',
            duration: 3,
            placement: 'top',
        });
    }, []);

    function sendData(thm: PaymentPageLayoutResponse | 'default', logo?: string, css?: string, htmlText?: string) {
        console.log('Sending data');
        if (iframe.current) {
            console.log('Post message to HPP Application');
            iframe.current.contentWindow!.postMessage(
                { preview: true, theme: thm, assets: { logo, css, htmlText } },
                `${process.env.REACT_APP_HPP_FE_DOMAIN}/preview`
            );
        }
    }

    async function updateTheme(id: number | string) {
        hasFetched.current = true;
        let result;
        if (themeId && themeId !== 'default') result = await getLayout(['themeId', id]);
        if (result && result.data && result.data.id) {
            let css;
            let logo;
            let html;
            if (result.data.logoId) logo = await getLogo({ id: result.data.logoId, responseType: 'data' });
            if (result.data.cssId) css = await getFile(['css', result.data.cssId]);
            if (result.data.termsAndConditions?.htmlFileId)
                html = await getFile(['html', result.data.termsAndConditions.htmlFileId]);

            const logoFile = logo as { data: string };
            let cssFile;
            let htmlFile;
            if (css?.data?.file) cssFile = { data: css.data.file } as { data: string };
            if (html?.data?.file) htmlFile = { data: html.data.file } as { data: string };

            return sendData(result.data, logoFile?.data, cssFile?.data, htmlFile?.data);
        }
        const tim = setTimeout(() => {
            sendData('default');
            clearTimeout(tim);
        }, 200);
    }

    const returnUrl = backUrl ? decodeURIComponent(backUrl) : '/admin/clients';

    return (
        <div style={{ width: '100%', height: '100vh' }} data-testid="hpp-preview">
            <div style={{ width: '100%', height: '100%' }}>
                <iframe
                    onLoad={() => updateTheme(themeId || 'default')}
                    ref={iframe}
                    title="paymentForm"
                    src={`${process.env.REACT_APP_HPP_FE_DOMAIN}/preview`}
                    allow="fullscreen"
                    style={{ border: 'none', width: '100%', height: '100%' }}
                    data-testid="hpp-iframe"
                />
            </div>
            <div style={{ position: 'absolute', left: '60px', bottom: '60px' }}>
                <Link to={returnUrl}>
                    <Button type="primary" size="large" data-testid="preview-back-btn">
                        <ArrowLeftOutlined />
                        Return
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default HppPreview;
