import { useLocation } from 'react-router-dom';
import { Menu, Layout, Image } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';
import LogoIcon from '../../assets/Netvalve_Logo_Icon.png';
import LogoText from '../../assets/NetValve_Logo_Text_Lower.png';
import type { AuthRole, RoutePermissionsConfig } from '../../types';
import { generateMenuItems, getActiveMenuLink } from '../../libs/sideMenu';

const { Sider } = Layout;

type SideMenuProps = {
    open: boolean;
    userRoles: AuthRole[];
    routePermissions: RoutePermissionsConfig;
    closeMenu: () => void;
};

const LogoArea = styled.div<{ menuOpen: boolean }>`
    background: transparent;
    transition: all 0.2s ease-out;
    padding: ${(props) => (props.menuOpen ? '1.8rem 4rem' : '2.1rem')};
    height: 10rem;
    display: flex;
    align-items: flex-start;

    #LogoText {
        transition: 0.2s ease-in;
        opacity: ${(props) => (props.menuOpen ? 1 : 0)};
        display: ${(props) => (props.menuOpen ? 'block' : 'none')};
        margin-left: 10px;
        margin-top: 0.5rem;
    }

    #LogoIcon {
        max-width: 40px;
    }
`;

const SideMenu: React.FC<SideMenuProps> = ({ open, userRoles, routePermissions, closeMenu }) => {
    const [belowBreakPoint, setBeloWBreakPoint] = useState(open);
    const location = useLocation();

    return (
        <Sider
            breakpoint="lg"
            width="250"
            collapsedWidth={belowBreakPoint ? 0 : undefined}
            trigger={null}
            collapsed={!open}
            onBreakpoint={(broken) => {
                if (belowBreakPoint !== broken) setBeloWBreakPoint(broken);
                if (broken) {
                    closeMenu();
                }
            }}
        >
            <LogoArea menuOpen={open}>
                <Image id="LogoIcon" preview={false} src={LogoIcon} />
                <Image id="LogoText" preview={false} src={LogoText} />
            </LogoArea>
            <Menu
                theme="dark"
                mode="inline"
                items={generateMenuItems(routePermissions, userRoles)}
                selectedKeys={getActiveMenuLink(routePermissions, location.pathname)}
            />
        </Sider>
    );
};

export default SideMenu;
