import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storeAuthToken } from '../../libs/storeAuthToken';
import { removeLocallyStoredValue } from '../../utils/localStorage';
import { authApi } from '../services/auth';
import { applyLocalStorageAuth, removeAppTokens } from './actions';
import type { AuthActionPayload } from './actions';
import type { AuthRole } from '../../types';

type AuthState = {
    token: string | null;
    refreshToken: string | null;
    groups: AuthRole[] | null;
};

const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null, refreshToken: null, groups: null } as AuthState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(applyLocalStorageAuth, (state, action: PayloadAction<AuthActionPayload>) => {
            state.token = action.payload.token;
            state.groups = action.payload.groups;
            state.refreshToken = action.payload.refreshToken;
        });

        builder.addCase(removeAppTokens, (state) => {
            state.token = null;
            state.groups = null;
            state.refreshToken = null;
            removeLocallyStoredValue('netvalve_tkn_ac');
        });

        builder.addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, { payload }) => {
            state.token = payload.accessToken;
            state.refreshToken = payload.refreshToken;
            state.groups = payload.groups;
            // STORE IN LOCAL STORAGE
            storeAuthToken(payload.accessToken, payload.refreshToken, payload.groups, payload.expiresIn);
        });
    },
});

export default authSlice.reducer;
