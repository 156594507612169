import { ApprovalRateResponse } from '../../../types';

export const mockApprovalRateResponse: ApprovalRateResponse = {
    averageApprovalRate: 75,
    approvalRateList: [
        {
            dateTime: '2023-08-23',
            approvalRate: 73,
        },
        {
            dateTime: '2023-08-24',
            approvalRate: 76,
        },
        {
            dateTime: '2023-08-25',
            approvalRate: 70,
        },
        {
            dateTime: '2023-08-26',
            approvalRate: 80,
        },
        {
            dateTime: '2023-08-27',
            approvalRate: 81,
        },
        {
            dateTime: '2023-08-28',
            approvalRate: 82,
        },
        {
            dateTime: '2023-08-29',
            approvalRate: 90,
        },
    ],
};

export const MockApprovalRateGraphData = {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    data: [[40, 46, 69, 80, 89, 97, 93, 91, 87, 70]],
};
