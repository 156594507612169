import { createAction } from '@reduxjs/toolkit';
import type { AuthRole } from '../../types';

export type AuthActionPayload = {
    token: string;
    refreshToken: string;
    groups: AuthRole[];
};

export const removeAppTokens = createAction<void>('auth/removeAppTokens');

export const applyLocalStorageAuth = createAction<AuthActionPayload>('auth/applyLocallyStoredTokens');
