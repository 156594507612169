import { Result, Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { extractErrorMessages } from '../../libs/errorHandlers';

type ErrorMessageProps = {
    returnToPageUrl?: string;
    message?: string;
    error: ReturnType<typeof extractErrorMessages>;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ returnToPageUrl, message, error }) => {
    return (
        <Result
            status="warning"
            title={message}
            subTitle={error.message ? `${error.error}. ${error.message}` : error.error}
            extra={
                <Link to={returnToPageUrl || '/'}>
                    <Button type="primary" key="console">
                        Go back
                    </Button>
                </Link>
            }
        />
    );
};

export default ErrorMessage;
