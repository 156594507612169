import { Spin } from 'antd';
import styled from 'styled-components';

const FlexContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NVSpin = ({ center }: { center?: boolean }) => {
    if (center)
        return (
            <FlexContainer>
                <Spin />
            </FlexContainer>
        );
    return <Spin />;
};

export default NVSpin;
