export const mockClients = [
    {
        id: 1,
        name: 'Test1',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
    {
        id: 2,
        name: 'Test2',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
    {
        id: 3,
        name: 'Test3',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
    {
        id: 4,
        name: 'Test4',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'INACTIVE',
    },
    {
        id: 5,
        name: 'Test5',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
    {
        id: 6,
        name: 'Test5',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
    {
        id: 7,
        name: 'Test5',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
    {
        id: 8,
        name: 'Test5',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
    {
        id: 9,
        name: 'Test5',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
    {
        id: 10,
        name: 'Test5',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
    {
        id: 11,
        name: 'Test11',
        email: 'Tes1@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
    {
        id: 12,
        name: 'Test12',
        email: 'Tes12@test.com',
        phone: 589105172,
        status: 'ACTIVE',
    },
];
