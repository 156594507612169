import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSignOutMutation } from '../store/services/auth';
import type { RootState } from '../store/index';
import { removeAppTokens } from '../store/slices/actions';

type SignOutHook = {
    logOut: () => void;
};

const useSignOut = (): SignOutHook => {
    const [signOut] = useSignOutMutation();
    const { refreshToken } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logOut = async () => {
        try {
            if (refreshToken) await signOut({ refreshToken });
        } catch (e) {
            throw new Error('Error calling signout api');
        }
        dispatch(removeAppTokens());
        navigate('/login');
    };

    return {
        logOut,
    };
};

export default useSignOut;
