/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { ErrorResponse, TransactionResponse } from '../types';

export function extractErrorMessages(res: any): Pick<ErrorResponse, 'error' | 'message'> {
    if (typeof res !== 'object' || !('data' in res) || !res) {
        return {
            error: '',
            message: '',
        };
    }
    const er = res as { data: any };
    if (er.data && 'status' in er.data) {
        const genericError = er.data as ErrorResponse;
        return {
            error: genericError.error,
            message: genericError.message,
        };
    }
    const transactionError = er.data as TransactionResponse;
    return {
        error: '',
        message: transactionError?.responseMessage,
    };
}

export function createErrorString(errorData?: ReturnType<typeof extractErrorMessages>) {
    if (!errorData || !errorData.error) return '';
    return errorData && errorData.message ? `${errorData.error}. ${errorData.message}` : errorData?.error;
}
