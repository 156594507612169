import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '.';

export const gatewayApi = createApi({
    baseQuery: baseQueryWithAuth,
    endpoints: () => ({}),
    tagTypes: [
        'ClientAuth',
        'Clients',
        'ClientUsers',
        'ClientUser',
        'ClientMids',
        'PaymentChannels',
        'Banks',
        'Currency',
        'ClientSecurity',
        'BasicAuth',
        'PaymentPage',
        'AllowedPaymentOptions',
        'AllowedButtonText',
        'Orders',
        'Order',
        'Transaction',
        'SummaryData',
        'ApprovalData',
        'AllClientMids',
        'GrossSaleData',
        'BatchData',
        'GrossSalesByCardBrand',
    ],
});
