import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { gatewayApi } from './services/gatewayApi';
import authReducer from './slices/auth';

export const storeConfig = {
    reducer: {
        auth: authReducer,
        [gatewayApi.reducerPath]: gatewayApi.reducer,
    },
};

export const store = configureStore({
    ...storeConfig,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(gatewayApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
