import React, { createContext, useContext, useMemo } from 'react';
import { notification } from 'antd';

type NotificationType = 'success' | 'error';

interface NotificationContextType {
    [key: string]: (message: string, description?: string) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

interface NotificationProviderProps {
    children: React.ReactNode;
}

const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
    const notify = (type: NotificationType, message: string, description?: string) => {
        notification[type]({
            message,
            description,
            placement: 'topRight',
            duration: 10,
        });
    };

    const notificationContextValue = useMemo<NotificationContextType>(
        () => ({
            success: (message: string, description?: string) => notify('success', message, description),
            error: (message: string, description?: string) => notify('error', message, description),
        }),
        []
    );

    return <NotificationContext.Provider value={notificationContextValue}>{children}</NotificationContext.Provider>;
};

const useToastNotification = (): NotificationContextType => {
    const context = useContext(NotificationContext);

    if (!context) {
        throw new Error('useToastNotification must be used within a NotificationProvider');
    }

    return context;
};

export { NotificationProvider, useToastNotification };
