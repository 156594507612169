import { GrossSalesResponse } from '../../../types';

export const mockGrossSaleResponse: GrossSalesResponse = {
    totalGrossSale: 13.0,
    totalGrossRefund: 17.0,
    grossSaleRefundList: [
        {
            dateTime: '2022-08-23 16:21:58',
            grossSaleAmount: 12.0,
            grossRefundAmount: 0.0,
        },
        {
            dateTime: '2023-02-23 16:22:29',
            grossSaleAmount: 25.0,
            grossRefundAmount: 0.0,
        },
        {
            dateTime: '2023-07-20 16:22:33',
            grossSaleAmount: 6.5,
            grossRefundAmount: 0.0,
        },
    ],
};
