import { ClientBasicAuth } from '../../../types';

export const mockBasicAuth: ClientBasicAuth = {
    id: 1,
    clientId: 1,
    midId: 1,
    password: 'sfdsfgo',
    securityType: 'BASIC_AUTH',
    username: 'username',
    status: 'ACTIVE',
    description: 'Yogesh Dahe',
};
