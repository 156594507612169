import { AreaChartOutlined, DollarOutlined, ProjectOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AuthRole, RoutePermissionsConfig } from '../types';

const MenuLink = styled(Link)`
    color: #1a90ff;

    li .ant-menu-item-icon {
        margin-left: 2rem;
    }
`;

//    // Define functions to get label and icon based on route path (implement these functions)
export const getLabelFromRoute = (routePath: string) => {
    if (routePath.includes('reports')) return 'Reports';
    if (routePath.includes('dashboard')) return 'Dashboard';
    if (routePath.includes('orders')) return 'Orders';
    if (routePath.includes('clients')) return 'Client Management';
    return 'Orders';
};

const getIconFromRoute = (routePath: string) => {
    if (routePath.includes('reports')) return <AreaChartOutlined />;
    if (routePath.includes('dashboard')) return <ProjectOutlined />;
    if (routePath.includes('orders')) return <DollarOutlined />;
    if (routePath.includes('clients')) return <UsergroupAddOutlined />;
    return <UsergroupAddOutlined />;
};

export function createMenuItemKey(roles: AuthRole[], link: string) {
    return roles.join('-').concat(link).concat('-menulink');
}

export function getActiveMenuLink(permissions: RoutePermissionsConfig, location: string) {
    const matchedKeys: string[] = [];
    Object.entries(permissions).find(([_, routeConfig]) => {
        if (!routeConfig.menuLinks) return false;
        const routeMatch = routeConfig.menuLinks.find((link) => location.includes(link));
        if (routeMatch) {
            matchedKeys.push(createMenuItemKey(routeConfig.allowedRoles, routeMatch));
            return true;
        }
        return false;
    });

    return matchedKeys;
}

export const generateMenuItems = (permissions: RoutePermissionsConfig, userRole: AuthRole[]) => {
    const menuItems: { label: React.ReactNode; icon: React.ReactNode; key: string }[] = [];

    Object.entries(permissions).forEach(([_, routeConfig]) => {
        // Add menu item if at least one of the allowedRoles matches the user's roles
        if (routeConfig.allowedRoles.some((allowedRole) => userRole.includes(allowedRole))) {
            if (routeConfig.menuLinks) {
                routeConfig.menuLinks.forEach((link) => {
                    menuItems.push({
                        label: <MenuLink to={link}>{getLabelFromRoute(link)}</MenuLink>,
                        icon: getIconFromRoute(link),
                        key: createMenuItemKey(routeConfig.allowedRoles, link),
                    });
                });
            }
        }
    });

    return menuItems;
};
