import { TransactionElement, TransactionDetail, TransactionResponse } from '../../../types';

export const mockOrdersPageOne = {
    content: [
        {
            id: 1,
            amount: 1000,
            currency: 'USD',
            createdDate: '2021-08-04T10:50:54',
            accountName: 'Account 1',
            status: 'Declined',
            customerEmail: 'gateway@gateway1.com',
        },
        {
            id: 2,
            amount: 1000,
            currency: 'USD',
            createdDate: '2021-08-04T10:52:22',
            accountName: 'Account 2',
            status: 'Declined',
            customerEmail: 'test@tesaccount12.com',
        },
        {
            id: 3,
            amount: 1000,
            currency: 'USD',
            createdDate: '2021-08-04T11:04:20',
            accountName: 'Account 3',
            status: 'Authorized',
            customerEmail: 'dummy@dummy3.com',
        },
        {
            id: 4,
            amount: 1000,
            currency: 'USD',
            createdDate: '2021-08-04T11:04:20',
            accountName: 'Account 4',
            status: 'Authorized',
            customerEmail: 'dummytest123@dummy4.com',
        },
    ],
    totalPages: 2,
    totalElements: 8,
    size: 4,
    number: 0,
    numberOfElements: 4,
};

export const mockOrdersPageTwo = {
    content: [
        {
            id: 5,
            amount: 1000,
            currency: 'USD',
            orderId: '105',
            createdDate: '2021-08-04T10:50:54',
            accountName: 'Account 5',
            status: 'Declined',
            customerEmail: 'gateway@gateway5.com',
        },
        {
            id: 6,
            amount: 1000,
            currency: 'USD',
            orderId: '106',
            createdDate: '2021-08-04T10:52:22',
            accountName: 'Account 6',
            status: 'Declined',
            customerEmail: 'test@tesaccount6.com',
        },
        {
            id: 7,
            amount: 1000,
            currency: 'USD',
            orderId: '107',
            createdDate: '2021-08-04T11:04:20',
            accountName: 'Account 7',
            status: 'Authorized',
            customerEmail: 'dummy@dummy7.com',
        },
        {
            id: 8,
            amount: 1000,
            currency: 'USD',
            orderId: '108',
            createdDate: '2021-08-04T11:04:20',
            accountName: 'Account 8',
            status: 'Authorized',
            customerEmail: 'dummytest123@dummy8.com',
        },
    ],
    totalPages: 2,
    totalElements: 8,
    size: 4,
    number: 0,
    numberOfElements: 4,
};

export const mockAuthorisedReferenceTransaction: TransactionElement = {
    amount: 800,
    currency: 'USD',
    createdDate: new Date(),
    status: 'Authorised',
    transactionType: 'Authorize',
    transactionId: '4334343',
    allowedActions: ['CAPTURE', 'CANCEL'],
    responseCode: 'GTW_1000',
    responseMessage: 'Transaction Approved/ Request Successful.',
    responseCodeType: 'APPROVED',
    authCode: null,
    bankResponseCode: '00',
};

export const mockReferenceTransactions = [
    {
        amount: 800,
        currency: 'USD',
        createdDate: '12 Feb 2020',
        status: 'Successful',
        transactionType: 'CAPTURE',
        allowedActions: ['REFUND'],
    },
    {
        amount: 100,
        currency: 'USD',
        createdDate: '10 Feb 2020',
        status: 'successful',
        transactionType: 'REFUND',
        allowedActions: ['NONE'],
    },
];

export const successfulTransactionResponse: TransactionResponse = {
    transactionID: 193,
    responseCode: 'GTW_1000',
    responseMessage: 'Transaction Approved/ Request Successful.',
    responseCodeType: 'APPROVED',
    traceID: '7174f500-e12d-415d-8541-cf3aa72989d4',
    responseTimestamp: new Date(),
};

export const failedTransactionResponse = {
    ...successfulTransactionResponse,
    transactionID: 173,
    responseCode: 'BNK_2003',
    responseMessage: 'An Error Message is here.',
};

export const mockAuthorisedOrder = {
    createdDate: '2021-09-10T09:06:11',
    status: 'Authorised',
    transactions: [] as unknown as TransactionElement[],
    amount: 800,
    orderAmount: 800,
    currency: 'USD',
} as unknown as Partial<TransactionDetail>;

export const mockSaleOrder = {
    createdDate: '2021-09-10T09:06:11',
    status: 'Sold',
    transactions: [] as unknown as TransactionElement[],
    amount: 800,
    orderAmount: 800,
    currency: 'USD',
    transactionSource: 'API',
} as unknown as Partial<TransactionDetail>;

export const idForCancelOperation = 163;
export const mockCancelReferenceTransaction: TransactionElement = {
    createdDate: new Date(),
    transactionType: 'CANCEL',
    amount: 800,
    currency: 'USD',
    status: 'Canceled',
    transactionId: `${idForCancelOperation}`,
    allowedActions: ['NONE'],
    responseCode: 'GTW_1000',
    responseMessage: 'Transaction Approved/ Request Successful.',
    responseCodeType: 'APPROVED',
    authCode: null,
    bankResponseCode: '00',
};

export const mockRefundReferenceTransaction: TransactionElement = {
    createdDate: new Date(),
    transactionType: 'REFUND',
    amount: 800,
    currency: 'USD',
    status: 'Refunded',
    transactionId: '242343255',
    allowedActions: ['NONE'],
    responseCode: 'GTW_1000',
    responseMessage: 'Transaction Approved/ Request Successful.',
    responseCodeType: 'APPROVED',
    authCode: null,
    bankResponseCode: '00',
};

export const mockSettleReferenceTransaction: TransactionElement = {
    createdDate: new Date(),
    transactionType: 'CAPTURE',
    amount: 800,
    currency: 'USD',
    status: 'Captured',
    transactionId: '45345345345345',
    allowedActions: ['NONE'],
    responseCode: 'GTW_1000',
    responseMessage: 'Transaction Approved/ Request Successful.',
    responseCodeType: 'APPROVED',
    authCode: null,
    bankResponseCode: '00',
};

export const mockSaleReferenceTransaction: TransactionElement = {
    createdDate: new Date(),
    transactionType: 'SALE',
    amount: 800,
    currency: 'USD',
    status: 'Sold',
    transactionId: '43545934543',
    allowedActions: ['REFUND'],
    responseCode: 'GTW_1000',
    responseMessage: 'Transaction Approved/ Request Successful.',
    responseCodeType: 'APPROVED',
    authCode: null,
    bankResponseCode: '00',
};
