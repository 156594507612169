import StyledButton from './StyledButton';

type LogoutButtonProps = {
    click: () => void;
};

const LogoutButton: React.FC<LogoutButtonProps> = ({ click }) => (
    <StyledButton data-testid="logoutBtn" type="primary" onClick={click}>
        Logout
    </StyledButton>
);

export default LogoutButton;
