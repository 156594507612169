import { Dayjs } from 'dayjs';
import { DonutDataType } from '../components/Charts/DonutChart';
// FOR DATES WWHERE THE DAY/MONTH ONLY SHOWS ONE INTEGER

export function addZero(num: number): string {
    if (num > 9) return `${num}`;
    return `0${num}`;
}

export function formatDate(dateObject: Date): string {
    if (!(dateObject instanceof Date)) return '';
    return `${addZero(dateObject.getDate())}/${addZero(dateObject.getMonth() + 1)}/${dateObject.getFullYear()}`;
}

export function createDateTimeString(datestamp: Date | string | undefined | null): string {
    if (!datestamp) return '';
    const date = new Date(datestamp);
    if (!(date instanceof Date) || `${date}` === 'Invalid Date') return '';
    return `${formatDate(date)} ${date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    })}`;
}

// TAKES THE MILLISECONDS ELAPSED SINCE THE UNIX EPOCH
export function withinExpirationDate(expirationTime: number): boolean {
    return Date.now() < expirationTime;
}

// CREATES THE MILLISECONDS ELAPSED SINCE THE UNIX EPOCH, ADDING HOURS FROM NOW
export function createUnixEpochElapsedExpireyTime(hoursFromNow: number, inPast = false): number {
    if (inPast) return new Date().getTime() - hoursFromNow * 60 * 60 * 1000;
    return new Date().getTime() + hoursFromNow * 60 * 60 * 1000;
}

export function capitalise(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getAmountInCurrency(curr: string, amount: string | number): string {
    const converter = new Intl.NumberFormat('en', { style: 'currency', currency: curr });
    return converter.format(+amount);
}

// RETRIEVE A COOKIE VALUE
export function getCookie(name: 'X-CSRF-TOKEN' | string): null | string {
    if (!document.cookie) {
        return null;
    }

    const selectedCookie = document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((c) => c.startsWith(`${name}=`));

    if (selectedCookie.length === 0) {
        return null;
    }
    return decodeURIComponent(selectedCookie[0].split('=')[1]);
}

export function cardNumberIsMasked(str: string): boolean {
    const regExp = /(^\d{6})(\*){4,10}(\d{4}\s?)$/g;
    return regExp.test(str);
}

// HELPER TO CHECK IF IN TEST ENVIRONMET
export const isTestEnv = (): boolean => process.env.NODE_ENV === 'test';

export function iframeEmbedded(): boolean {
    if (window.self !== window.top) {
        window.top!.location = window.self.location;
        return true;
    }
    return false;
}

export function readFile(file: File | Blob, readType: 'file' | 'text'): Promise<string> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                // convert image file to base64 string
                if (typeof reader.result === 'string') resolve(reader.result);
            },
            false
        );

        if (file) {
            if (readType === 'file') reader.readAsDataURL(file);
            else if (readType === 'text') reader.readAsText(file);
        }
    });
}

export function transformBlobToImage(response: Blob, convertToType: 'file' | 'data'): Promise<string> {
    return new Promise((resolve) => {
        if (convertToType === 'file') resolve(URL.createObjectURL(response));
        else resolve(readFile(response, 'file'));
    });
}

export async function transformBlobToTextFileData(response: Blob | File, meta: any) {
    const url = URL.createObjectURL(response);
    const d = meta as { response: { headers: { get: (n: string) => void } } };
    const filename = d.response.headers.get('Content-Disposition');
    const cssText = await readFile(response, 'text');
    return { filename, url, file: cssText };
}

export function isValidString(str: string | null) {
    return str !== null && str !== 'null';
}

const formatToISOString = (date: Date) => {
    return date.toISOString().slice(0, 19).replace('T', ' ');
};

export function getDateRange(daysAgo: number) {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - daysAgo);

    return {
        startDate: formatToISOString(startDate),
        endDate: formatToISOString(endDate),
    };
}

export function transformToDonutChartData(inputArray: DonutDataType[]) {
    const colors = ['#15395E', '#54C0E8', '#FEA043', '#FC5A5A']; // Array of colors

    // Generate the transformed array
    const transformedArray = inputArray.map((item, index) => ({
        category: item.category,
        value: item.value,
        color: colors[index % colors.length],
    }));

    return transformedArray;
}
// Function to remove specific parameters from the query string
export function removeParameters(queryString: string, parametersToRemove: string[]): string {
    const params = new URLSearchParams(queryString);
    for (const parameter of parametersToRemove) {
        params.delete(parameter);
    }
    return params.toString();
}

export function createOrderQueryString(
    page: number,
    pageSize: number,
    emailAddress?: string,
    orderId?: number,
    queryString?: string
): string {
    const emailQueryString = emailAddress ? `&emailAddress=${emailAddress}` : '';
    const orderIdQueryString = orderId ? `&orderId=${orderId}` : '';
    const filterQueryString = queryString ? `&${queryString}` : '';

    return `backoffice/orders?page=${
        orderId ? 0 : page
    }&pageSize=${pageSize}${emailQueryString}${orderIdQueryString}${filterQueryString}`;
}

export function formatReadableDate(date: Dayjs) {
    return date.format('MMMM D, YYYY');
}
