import { Result } from 'antd';
import styled from 'styled-components';

const NVResult = styled(Result)`
    .ant-result-title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        text-align: center;

        color: #1f1818;
    }

    svg {
        fill: #4cb3e1;
        font-size: 12rem;
        margin-bottom: 2rem;
    }

    button {
        margin-top: 4rem;
        width: 100%;
    }
`;

export default NVResult;
